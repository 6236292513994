import React, { useEffect, useRef, useState } from 'react'

import Play from '@interco/icons/core/media-communication/ic_play'
import BackwardStep from '@interco/icons/core/media-communication/ic_backward_step'
import ForwardStep from '@interco/icons/core/media-communication/ic_forward_step'
import PauseCircle from '@interco/icons/core/media-communication/ic_pause_circle'

import { AudioContainer, AudioPlay, Button } from './style'

type AudioData = {
  description: string
  file: {
    name: string
    url: string
  }
}

const getPercent = (duration: number, currentTime: number) => (100 / duration) * currentTime
export const Audio = ({ description, file }: AudioData) => {
  const ref = useRef(null)
  const [playing, setPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)

  const handlePlay = () => {
    if (ref) {
      if (playing) {
        ref.current.pause()
      } else {
        ref.current.play()
      }
      setPlaying(!playing)
      if (duration <= 0) {
        setDuration(ref.current.duration)
      }
    }
  }

  const advanced = () => {
    ref.current.currentTime += (duration / 100) * 10
  }

  const back = () => {
    ref.current.currentTime -= (duration / 100) * 10
  }

  useEffect(() => {
    if (currentTime > 0 && currentTime === duration) {
      if (ref) {
        setPlaying(false)
        ref.current.pause()
        ref.current.currentTime = 0
      }
    }
  }, [currentTime])

  return (
    <AudioContainer>
      <div className="d-flex align-items-center mb-3 mb-md-0">
        <Button onClick={handlePlay}>
          {playing ? (
            <PauseCircle height={24} width={24} color="#FF7A00" />
          ) : (
            <Play height={24} width={24} color="#FF7A00" />
          )}
        </Button>
        <h4 className="fs-12 lh-14 fw-400 grayscale--500 mb-0 ml-2">{description}</h4>
      </div>
      <div className="d-flex align-items-center">
        <Button onClick={back} disabled={currentTime <= (duration / 100) * 10}>
          <BackwardStep
            height={16}
            width={16}
            color={currentTime <= (duration / 100) * 10 ? '#c2c2c2' : '#161616'}
            className="mr-2"
          />
        </Button>
        <AudioPlay percent={`${getPercent(duration, currentTime)}%`}>
          <audio
            ref={ref}
            id="player"
            controls
            controlsList="nodownload"
            onTimeUpdate={() => setCurrentTime(ref.current.currentTime)}
            src={file.url}
          >
            <track kind="captions" />
          </audio>
        </AudioPlay>
        <Button onClick={advanced} disabled={currentTime + (duration / 100) * 10 >= duration}>
          <ForwardStep
            height={16}
            width={16}
            color={currentTime + (duration / 100) * 10 >= duration ? '#c2c2c2' : '#161616'}
            className="ml-2"
          />
        </Button>
      </div>
    </AudioContainer>
  )
}
