import styled from 'styled-components'
import { grayscale, white } from 'src/styles/colors'
import breakpoints from 'src/styles/breakpoints'

type PercentProps = {
  percent: string
}

export const AudioContainer = styled.div`
  border: 1px solid ${grayscale[200]};
  border-radius: 12px;
  display: flex;
  align-items: center;
  margin: 32px 0;
  justify-content: space-between;
  max-width: 100%;
  padding: 20px;
  flex-direction: column;

  @media (min-width: ${breakpoints.md}) {
    flex-direction: row;
  }

  audio {
    margin-top: 10px;
    width: 100%;
    display: none;
  }
  audio::-webkit-media-controls-panel {
    background-color: ${white};
  }
  audio::-webkit-media-controls-toggle-closed-captions-button {
    display: none;
  }
`
export const AudioPlay = styled.div<PercentProps>`
  width: 213px;
  height: 4px;
  border-radius: 2px;
  background: rgba(178, 223, 219, 1);
  background: linear-gradient(
    90deg,
    rgba(0, 121, 107, 1) ${({ percent }) => percent},
    rgba(178, 223, 219, 1) ${({ percent }) => percent}
  );
`
export const Button = styled.button`
  background: none;
  border: none;
  padding: none;
  cursor: pointer;
`
